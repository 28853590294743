import { render, staticRenderFns } from "./DialogInvitationRewards.vue?vue&type=template&id=7f5f0596&scoped=true&"
import script from "./DialogInvitationRewards.vue?vue&type=script&lang=js&"
export * from "./DialogInvitationRewards.vue?vue&type=script&lang=js&"
import style0 from "./DialogInvitationRewards.vue?vue&type=style&index=0&id=7f5f0596&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f5f0596",
  null
  
)

export default component.exports