<template>
<div>
  <div v-if="data.isShow" class="dialog-login">
<div class="position-img">
<img src="../../assets/images/store-design/kkj-green.png" alt="" srcset="">
</div>
<div class="box">
  <div class="login-header">
    <img src="../../assets/images/store-design/kkj-white.png" alt="" srcset="">
  </div>
    <div class="body-wrap">
      <el-form ref="loginForm" label-position="top" label-width="120px" :rules="rules" :model="data.form">
        <el-form-item label="E-Mail" prop="email">
          <el-input v-model="data.form.email" placeholder="Please enter your email address" :readonly="readonly"></el-input>
        </el-form-item>
        <el-form-item label="Password" prop="password">
          <el-input v-model="data.form.password" show-password placeholder="Please input a password" :readonly="readonly" @keyup.enter.native="login"></el-input>
        </el-form-item>
      </el-form>  
      <div style=" padding: 0 20px 10px 20px">
        <el-link type="primary" @click="switchTo('forgotPSD')">Forgot your password?</el-link>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <div style="display: flex; justify-content: center; margin-bottom: 20px;">
        <el-button type="primary" @click="login" :loading="data.loading" style="padding: 12px 40px;">LOGIN</el-button>
      </div>
      <div style=" display: flex; justify-content: flex-end; align-items: center; color: #909399;">
        <span style=" margin-right: 15px;">No Account?</span>  <el-link type="primary" @click="switchTo('register')">Sign up here</el-link>
      </div>
    </div>
</div>

  </div>

</div>

</template>

<script>
export default {
  props: ["data"],
  data(){
    return {
      readonly: true,
      rules: {
        email: [
          {required: true, message: 'Email must be filled in', trigger: 'blur'},
        ],
        password: [
          {required: true, message: 'Password must be filled in', trigger: 'blur'},
        ],
      }
    }
  },
  created() {
  },
  mounted () {
      this.$nextTick(()=>{
        this.readonly = false;
        // this.$refs['loginForm'].clearValidate(); //清除验证
      })
  },
  methods: {
    login(){
      this.data.loading = true;
      this.$refs['loginForm'].validate((valid) => {
        if(valid){ //验证成功
          let params = {
            username: this.data.form.email,
            password: this.data.form.password,
            type: 1,
            lang: "EN",
          }
          this.$emit('login', params);
        }else{ //验证失败
          this.data.loading = false;
          return false;
        }
      })
    },
    switchTo(type){
      this.$emit('switchTo', type)
    },
    opened(){
      this.$nextTick(()=>{
        this.readonly = false;
        this.$refs['loginForm'].clearValidate(); //清除验证
      })
    },
  },
}
</script>


<style scoped lang="scss">
.dialog-login{
  width: 100%;
  height: 100vh;
  background: #91B659;
  display: flex;
  justify-content: center;
  align-items: center;
  .el-form--label-top{
    margin: 0 20px;
    .el-form-item{
      margin-bottom: 15px;
      .el-form-item__label{
        margin-bottom: 0;
        padding-bottom: 0;
      } 
      .el-form-item__content{
        .el-form-item__error{
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
.login-header{
  display: flex;
  justify-content: center;
  margin-top: 15px;
  img{
    height: 55px;
  }
}
.position-img{
  position: fixed;
  top: 60px;
  left: 0;
    img{
      width: 190px;
    height: 80px;
  }
}
.box{
  width: 383px;
  height: 500px;
  background: #fff;
  margin: 0 auto;
  padding: 20px;
}
.el-form--label-top ::v-deep.el-form-item__label{
padding: 0;
}
</style>