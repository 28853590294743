//沙盒：develop； 正式：api
import axios from 'axios'
let arr = window.location.hostname.split('.')
//host:线上 hostLocal：本地
let host = `${window.location.hostname}/api`,
// let hostLocal = 'mall.mabangerp.com/api',
  pointCnt = 0;
if (window.location.host.match(/\./ig)) pointCnt = window.location.host.match(/\./ig).length;
let protocol = 'https:' == document.location.protocol ? 'https' : 'https';
let axiosWrap = axios.create({
  method: 'post',
  baseURL: protocol + "://" + host + "/h5.php",
});
axiosWrap.interceptors.request.use(
  config => {
      config.params = {
        _t: new Date().valueOf(),
        ...config.params
      }
      if(!pointCnt)
        config.params._api = config.data.get('api').replace('api.','');
    return config
  }, function (error) {
    return Promise.reject(error)
  }
)
export default axiosWrap